.sidebar {
    position: sticky;
    position: -webkit-sticky;
    top: 20px;
    background: var(--white-color);
}

/* Service Details Sidenav */
.sidenav ul {
    padding: 0px;
    margin: 0px;
}

.sidenav ul li {
    list-style: none;
    display: inline-block;
    width: 100%;
    margin: 3px 0px;
}

.sidenav ul li a {
    position: relative;
    font-family: var(--font-family-heading);
    font-size: var(--font-16);
    font-weight: 700;
    text-transform: capitalize;
    line-height: 26px;
    color: var(--main-color);
    list-style: none;
    background: var(--white-color);
    padding: 17px 22px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0px 0px 30px rgba(137, 151, 186, 0.1);
}

.sidenav ul li a:hover {
    background: var(--color-two);
    color: var(--white-color);
}

.sidenav ul li a:hover img:first-child {
    opacity: 0;
    visibility: hidden;
}

.sidenav ul li a:hover img:last-child {
    opacity: 1;
    visibility: visible;
}

.sidenav ul li a img:first-child {
    opacity: 1;
    visibility: visible;
    margin-right: 16px;
    transition: all 0.5s ease-in;
}

.sidenav ul li a img:last-child {
    opacity: 0;
    visibility: hidden;
    margin-left: -40px;
    margin-right: 16px;
    transition: all 0.5s ease-in;
}

.sidenav ul li.active a {
    background: var(--color-two);
    color: var(--white-color);
}

.sidenav ul li.active a i {
    color: var(--white-color);
}

.sidenav ul li.active a img:first-child {
    opacity: 0;
}

.sidenav ul li.active a img:last-child {
    opacity: 1;
    visibility: visible;
}
/* Service Details Sidebar */


/* Blog Sidebar */
.widget {
    background-color: var(--white-color);
    box-shadow: 0px 10px 60px rgba(137, 151, 186, 0.15);
    border-radius: 10px;
    padding: var(--space-30);
    margin-bottom: var(--space-30);
}
.widget h2, .widget .h2,
.widget .widget-title {
    position: relative;
    font-size: var(--font-20);
    font-weight: 700;
    border-radius: 50px;
    color: var(--main-color);
    text-transform: capitalize;
    margin-bottom: var(--space-20);
}
.widget h2:before, .widget .h2:before,
.widget .widget-title:before {
    position: absolute;
    content: '';
    left: 0px;
    bottom: 0px;
    width: 30px;
    height: 3px;
    background-color: var(--color-two);
}

.widget ul, .widget ol {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
}

.widget ul li, .widget ol li {
  margin-bottom: 0px;
  border-bottom: 1px solid rgba(174, 182, 194, 0.15);
  padding: 12px 0px;
}

.widget ul li a, .widget ol li a {
  width: 100%;
}

.widget ul li .submenu-container, .widget ol li .submenu-container {
  padding-left: 20px;
}

.widget .wp-block-page-list li {
  border-bottom: 0px;
  padding: 0px;
}

.widget .wp-block-page-list li a {
  border-bottom: 1px solid rgba(174, 182, 194, 0.15);
  padding: 12px 0px;
  width: 100%;
  display: block;
}

.widget .wp-calendar-table {
  width: 100%;
  border: 1px solid #2e2e2e;
}

.widget .wp-calendar-table td, .widget .wp-calendar-table th {
  border-bottom: 1px solid #2e2e2e;
  border-right: 1px solid #2e2e2e;
}

.widget .wp-calendar-table caption {
  color: #ffffff;
}

/*-- Search --*/
.widget_search {
  text-align: left;
  padding: var(--space-0);
  box-shadow: none;
}

.widget_search .wp-block-search__inside-wrapper {
  position: relative;
}

.widget_search .wp-block-search {
  position: relative;
}

.widget_search .wp-block-search .wp-block-search__input {
  padding: 5px 15px;
  height: 58px;
  border-radius: 5px 0px 0px 5px;
  background: var(--color-five);
  color: var(--main-color);
}

.widget_search .wp-block-search .wp-block-search__input:focus {
  box-shadow: none;
}

.widget_search .wp-block-search .wp-block-search__button {
  position: absolute;
  top: 0;
  right: 0;
  border: 0px;
  font-size: 16px;
  text-align: center;
  background: var(--color-two);
  color: var(--white-color);
  width: 58px;
  height: 58px;
  line-height: 58px;
  border-radius: 5px;
  padding: var(--space-0);
  cursor: pointer;
  box-shadow: 0px 10px 20px rgba(242, 100, 100, 0.3);
}

.widget_search .wp-block-search .wp-block-search__button:focus {
  outline: none;
}

.widget_search .wp-block-search ::-webkit-input-placeholder {
  font-size: 0.75rem;
  font-weight: 500;
  color: #a6a182;
}

.widget_search .wp-block-search ::-moz-placeholder {
  font-size: 0.75rem;
  font-weight: 500;
  color: #a6a182;
}

.widget_search .wp-block-search :-ms-input-placeholder {
  font-size: 0.75rem;
  font-weight: 500;
  color: #a6a182;
}

.widget_search .wp-block-search :-moz-placeholder {
  font-size: 0.75rem;
  font-weight: 500;
  color: #a6a182;
}

.widget_search .wp-block-search :placeholder {
  font-size: 0.75rem;
  font-weight: 500;
  color: #a6a182;
}

/*-- Author --*/
.sidebar_author {
  position: relative;
}

.sidebar_author img {
  width: 100%;
  margin: 0 auto;
  display: block;
  margin-bottom: 25px;
}

.sidebar_author .intro {
  position: relative;
  font-size: var(--font-16);
  font-weight: 400;
  line-height: 26px;
  margin-top: var(--space-20);
  margin-bottom: var(--space-5);
  color: var(--main-color);
}

.sidebar_author .author_social {
  margin-top: var(--space-30);
}

.sidebar_author .author_social ul {
  margin: 0px;
  padding: 0;
  display: flex;
  align-items: center;
}

.sidebar_author .author_social ul li {
  list-style-type: none;
  width: auto;
  padding: var(--space-0);
  border-width: 0px;
}

.sidebar_author .author_social ul li:not(:last-child) {
  margin-right: var(--space-10);
}

.sidebar_author .author_social ul li a {
  background: var(--color-five);
  display: block;
  font-size: var(--font-15);
  text-align: center;
  color: var(--main-color);
  width: 38px;
  height: 38px;
  line-height: 38px;
  border-radius: 100%;
}

.sidebar_author .author_social ul li a:hover {
  background: var(--white-color);
  color: var(--color-two);
  box-shadow: 0px 10px 20px rgba(137, 151, 186, 0.25);
}

/* Latest Posts */
ul.wp-block-latest-posts li {
  display: flex;
  align-items: center;
  padding: 25px 0;
  border-bottom: 1px solid rgba(174, 182, 194, 0.15);
}

ul.wp-block-latest-posts li:first-child {
  padding-top: 0px;
}

ul.wp-block-latest-posts li:last-child {
  padding-bottom: 0px;
  border-bottom-width: 0px;
}

ul.wp-block-latest-posts li .latest-posts-image {
  width: 130px;
  border-radius: 5px;
  flex-shrink: 0;
  margin-right: var(--space-20);
}

ul.wp-block-latest-posts li h5 {
  font-size: var(--font-16);
  line-height: 18px;
  color: var(--main-color);
  margin-bottom: var(--space-5);
  word-break: break-word;
}

ul.wp-block-latest-posts li h5 a {
  color: var(--main-color);
}

ul.wp-block-latest-posts li h5 a:hover {
  color: var(--color-two);
}

ul.wp-block-latest-posts li h6 {
  font-family: var(--font-family-base);
  font-size: var(--font-12);
  font-weight: 400;
  text-transform: uppercase;
  color: var(--color-two);
}


/* Categories */
ul.wp-block-categories li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--font-16);
  color: var(--color-six);
  border-width: 0px;
  padding: 8px 0px;
}
ul.wp-block-categories li a {  
  font-size: var(--font-16);
  color: var(--color-six);
}
ul.wp-block-categories li:hover,
ul.wp-block-categories li a:hover {
  color: var(--color-two);
}

/*-- Tag Cloud --*/
.widget_tag_cloud a {
  position: relative;
  display: inline-block;
  font-size: var(--font-12);
  text-transform: uppercase;
  letter-spacing: 0.7px;
  background: #ffeeef;
  color: var(--color-two);
  border-radius: 5px;
  padding: 2px 14px;
  margin: 0px 0px 5px 0px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

.widget_tag_cloud a::before {
  content: '';
  position: absolute;
  left: -150%;
  bottom: -150%;
  background-color: rgba(var(--white-color-rgb),0.6);
  width: 150%;
  height: 150%;
  transform: rotate(45deg);
  border-radius: 0;
  transition: all 0.8s ease-in-out;
}
.widget_tag_cloud a:hover::before {
  left: 150%;
  bottom: 150%;
}

.widget_tag_cloud a:hover {
  background-color: var(--color-two);
  color: var(--white-color);
}
