
/*** 

====================================================================
	Main Slider Section
====================================================================

***/

#aslide-close{
	left: -500px;
}

.main-slider{
	position:relative;
	overflow:hidden;
	margin-top: 100px;
}



.main-slider .slide{
	position:relative;
	padding: 230px 0px;
	background-size:cover;
	background-position: center center;
}

.main-slider .pattern-layer-one{
	position:absolute;
	left: 0px;
	top: 0px;
}
.main-slider .swiper-slide-active .pattern-layer-one {
	animation: sliderobject-move1 4s;
}
.main-slider .pattern-layer-two{
	position:absolute;
	right: 0px;
	bottom: 0px;
}
.main-slider .swiper-slide-active .pattern-layer-two {
	animation: sliderobject-move2 4s;
}

.main-slider .pattern-layer-three{
	position:absolute;
	right: 0px;
	top: 0px;
}
.main-slider .swiper-slide-active .pattern-layer-three {
	animation: sliderobject-move1 4s;
}
.main-slider .pattern-layer-four{
	position:absolute;
	left: 0px;
	bottom: 0px;
}
.main-slider .swiper-slide-active .pattern-layer-four {
	animation: sliderobject-move2 4s;
}

@keyframes sliderobject-move1 {
    0% {
    	top: -50px;
    }
    100% {
    	top: 0px;
    }
}

@keyframes sliderobject-move2 {
    0% {
    	bottom: -50px;
    }
    100% {
    	bottom: 0px;
    }
}

.main-slider .image-layer img{
	position:absolute;
}

.main-slider .image-layer{
	position:absolute;
	left:0px;
	top:0px;
	right:0px;
	bottom:0px;
	filter: grayscale(100%);
	background-size:cover;
	-webkit-transform:scale(1);
	-ms-transform:scale(1);
	transform:scale(1);
	background-position:right center;
	-webkit-transition: all 6000ms linear;
	-moz-transition: all 6000ms linear;
	-ms-transition: all 6000ms linear;
	-o-transition: all 6000ms linear;
	transition: all 6000ms linear;
}

.main-slider .swiper-slide-active .image-layer{
	-webkit-transform:scale(1.15);
	-ms-transform:scale(1.15);
	transform:scale(1.15);
}

.main-slider .image-layer:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	right:0px;
	bottom:0px;
	z-index:1;
	opacity:0.90;
	background-color:var(--white-color);
}

.main-slider .content-column{
	position:relative;
	z-index:2;
}

.main-slider .content-column .inner-column{
	position:relative;
}

.main-slider .title{
	position:relative;
	opacity: 0;
	z-index:1;
	font-weight:500;
	font-size:var(--font-15);
	text-transform:uppercase;
	letter-spacing: 2px;
	color:var(--main-color);
	transform-origin: top;
	display:inline-block;
	transform: translateX(200px);
	-webkit-transition: all 1000ms ease;
	-moz-transition: all 100ms ease;
	-ms-transition: all 1000ms ease;
	-o-transition: all 1000ms ease;
	transition: all 1000ms ease;
	margin-bottom:var(--space-15);
	font-family:var(--font-family-inter);
}

.main-slider .swiper-slide-active .title{
	opacity: 1;
	transform: translateX(0px);
	-webkit-transition-delay: 500ms;
	-moz-transition-delay: 500ms;
	-ms-transition-delay: 500ms;
	-o-transition-delay: 500ms;
	transition-delay: 500ms;
}

.main-slider h1{
	opacity: 0;
	color:var(--main-color);
	transform-origin: top;
	transform: translateX(200px);
	-webkit-transition: all 1000ms ease;
	-moz-transition: all 100ms ease;
	-ms-transition: all 1000ms ease;
	-o-transition: all 1000ms ease;
	transition: all 1000ms ease;
}

.main-slider h1 span{
	font-weight:700;
	color:var(--color-two);
}

.main-slider .swiper-slide-active h1{
	opacity: 1;
	transform: translateX(0px);
	-webkit-transition-delay: 800ms;
	-moz-transition-delay: 800ms;
	-ms-transition-delay: 800ms;
	-o-transition-delay: 800ms;
	transition-delay: 800ms;
}

.main-slider .text{
	opacity: 0;
	color:var(--color-three);
	transform-origin: top;
	font-size:var(--font-18);
	transform: translateX(200px);
	-webkit-transition: all 1000ms ease;
	-moz-transition: all 100ms ease;
	-ms-transition: all 1000ms ease;
	-o-transition: all 1000ms ease;
	transition: all 1000ms ease;
	margin-top:var(--space-20);
	margin-bottom:var(--space-35);
}

.main-slider .swiper-slide-active .text{
	opacity: 1;
	transform: translateX(0px);
	-webkit-transition-delay: 1200ms;
	-moz-transition-delay: 1200ms;
	-ms-transition-delay: 1200ms;
	-o-transition-delay: 1200ms;
	transition-delay: 1200ms;
}

.main-slider .options-box{
	opacity:0;
	transform: translateX(200px);
	transform-origin: top;
	display:inline-block;
	-webkit-transition: all 900ms ease;
	-moz-transition: all 900ms ease;
	-ms-transition: all 900ms ease;
	-o-transition: all 900ms ease;
	transition: all 900ms ease;
}

.main-slider .swiper-slide-active .options-box{
	opacity: 1;
	transform: translateX(0px);
	-webkit-transition-delay: 1600ms;
	-moz-transition-delay: 1600ms;
	-ms-transition-delay: 1600ms;
	-o-transition-delay: 1600ms;
	transition-delay: 1600ms;
}

.main-slider .button-box {
	gap: var(--space-25);
}
.main-slider .button-box .btn{
	display:block;
}

.main-slider .image-column{
	position:relative;
	z-index:2;
}

.main-slider .image-column .inner-column{
	position:relative;
	padding-right:var(--space-30);
}

.main-slider .image{
	position:relative;
	z-index:2;
	opacity: 0;
	transform: translateX(-200px);
	transform-origin: top;
	
	margin-left:-85px;
	-webkit-transition: all 1000ms ease;
	-moz-transition: all 100ms ease;
	-ms-transition: all 1000ms ease;
	-o-transition: all 1000ms ease;
	transition: all 1000ms ease;
}

.main-slider .swiper-slide-active .image{
	opacity: 1;
	transform: translateX(0px);
	-webkit-transition-delay: 600ms;
	-moz-transition-delay: 600ms;
	-ms-transition-delay: 600ms;
	-o-transition-delay: 600ms;
	transition-delay: 600ms;
}


/*** 

====================================================================
	Second Slider Section
====================================================================

***/

.second-slider{
	position:relative;
	overflow:hidden;
}

.second-slider .owl-dots,
.second-slider .owl-nav{
	display:none;
}

.second-slider .slide{
	position: relative;
	padding: 50px 0px 0px;
	background-size: cover;
	overflow: hidden;
}
.second-slider .slide .slide-inner::before {
	content: url('../../../public/images/slider/shape-3.png');
	position: absolute;
	right: 55%;
	bottom: -10px;
	z-index: 1;
	transition: all 1.5s ease-in-out;
}
.second-slider .slide .slide-inner::after {
	content: url('../../../public/images/slider/shape-3-right.png');
	position: absolute;
	left: 60%;
	bottom: -30px;
	z-index: 1;
	transition: all 1.5s ease-in-out;
}

.second-slider .image-layer img{
	position:absolute;
}

.second-slider .image-layer{
	position:absolute;
	left:0px;
	top:0px;
	right:0px;
	bottom:0px;
	background-size:cover;
	-webkit-transform:scale(1);
	-ms-transform:scale(1);
	transform:scale(1);
	background-position: center;
	-webkit-transition: all 6000ms linear;
	-moz-transition: all 6000ms linear;
	-ms-transition: all 6000ms linear;
	-o-transition: all 6000ms linear;
	transition: all 6000ms linear;
}


.second-slider .content-column{
	position:relative;
	z-index:2;
    padding-top: 200px;
	padding-bottom: 175px;
}
.second-slider .content-column::before {
	content: url('../../../public/images/slider/5.png');
	position: absolute;
	right: 90%;
	bottom: -10px;
	transition: all 1.8s ease-in-out;
}
.second-slider .content-column::after {
	content: url('../../../public/images/slider/6.png');
	position: absolute;
	left: 90%;
	bottom: -10px;
	transition: all 1.8s ease-in-out;
}

.second-slider .content-column .inner-column{
	position:relative;
	z-index: 1;
}
.second-slider .title{
	position:relative;
	z-index:1;
	font-weight:500;
	font-size:var(--font-15);
	text-transform:uppercase;
	letter-spacing: 2px;
	color:var(--color-two);
	display:inline-block;
	-webkit-transition: all 1000ms ease;
	-moz-transition: all 100ms ease;
	-ms-transition: all 1000ms ease;
	-o-transition: all 1000ms ease;
	transition: all 1000ms ease;
	margin-bottom:var(--space-15);
	font-family:var(--font-family-inter);
}


.second-slider h1{
	color:var(--main-color);
	-webkit-transition: all 1000ms ease;
	-moz-transition: all 100ms ease;
	-ms-transition: all 1000ms ease;
	-o-transition: all 1000ms ease;
	transition: all 1000ms ease;
}

.second-slider h1 span{
	font-weight:700;
	color:var(--color-two);
}


.second-slider .text{
	color:var(--color-three);
	transform-origin: top;
	font-size:var(--font-18);
	-webkit-transition: all 1000ms ease;
	-moz-transition: all 100ms ease;
	-ms-transition: all 1000ms ease;
	-o-transition: all 1000ms ease;
	transition: all 1000ms ease;
	margin-top:var(--space-20);
	margin-bottom:var(--space-35);
}


.second-slider .options-box{
	display:inline-block;
	-webkit-transition: all 900ms ease;
	-moz-transition: all 900ms ease;
	-ms-transition: all 900ms ease;
	-o-transition: all 900ms ease;
	transition: all 900ms ease;
}

.second-slider .button-box {
	gap: var(--space-25);
}
.second-slider .button-box .btn{
	display: block;
}

.second-slider .image-column{
	position:relative;
	z-index:2;
}

.second-slider .image-column .inner-column{
	position:relative;
	padding-right:var(--space-30);
}

.second-slider .image{
	position:relative;
	z-index:2;
	margin-left:-85px;
	-webkit-transition: all 1000ms ease;
	-moz-transition: all 100ms ease;
	-ms-transition: all 1000ms ease;
	-o-transition: all 1000ms ease;
	transition: all 1000ms ease;
}



/*** 

====================================================================
	Third Slider Section
====================================================================

***/

.third-slider{
	position:relative;
	overflow:hidden;
}

.third-slider .owl-dots,
.third-slider .owl-nav{
	display:none;
}

.third-slider .slide{
	position: relative;
	padding: 50px 0px 0px;
	background-size: cover;
	overflow: hidden;
}
.third-slider .slide .slide-inner::after {
	content: url('../../../public/images/slider/graph.png');
	position: absolute;
	left: 60%;
	bottom: -100px;
	z-index: 1;
	transition: all 1.5s ease-in-out;
}
.third-slider .swiper-slide-active .slide .slide-inner::after {
	bottom: -30px;
}

.third-slider .image-layer img{
	position:absolute;
}

.third-slider .image-layer{
	position:absolute;
	left:0px;
	top:0px;
	right:0px;
	bottom:0px;
	background-size:cover;
	-webkit-transform:scale(1);
	-ms-transform:scale(1);
	transform:scale(1);
	background-position: center;
	-webkit-transition: all 6000ms linear;
	-moz-transition: all 6000ms linear;
	-ms-transition: all 6000ms linear;
	-o-transition: all 6000ms linear;
	transition: all 6000ms linear;
}

.third-slider .swiper-slide-active .image-layer{
	-webkit-transform:scale(1.15);
	-ms-transform:scale(1.15);
	transform:scale(1.15);
}

.third-slider .content-column{
	position:relative;
	z-index:2;
    padding-top: 200px;
	padding-bottom: 200px;
}
.third-slider .content-column .content-image-layer {
	position: absolute;
	left: 150%;
	bottom: 0px;
	width: 100%;
	transition: all 1.8s ease-in-out;
}
.third-slider .content-column .content-image-layer img {
	width: auto;
}
.third-slider .swiper-slide-active .content-column .content-image-layer {
	left: 100%;
}
.third-slider .content-column .inner-column{
	position:relative;
	z-index: 1;
}
.third-slider .title{
	position:relative;
	opacity: 0;
	z-index:1;
	font-family: var(--font-family-inter);
	font-size:var(--font-16);
	font-weight:500;
	text-transform:uppercase;
	letter-spacing: 2px;
	color: var(--white-color);
	transform-origin: top;
	display:inline-block;
	transform: translateX(200px);
	-webkit-transition: all 1000ms ease;
	-moz-transition: all 100ms ease;
	-ms-transition: all 1000ms ease;
	-o-transition: all 1000ms ease;
	transition: all 1000ms ease;
	margin-bottom:var(--space-15);
	font-family:var(--font-family-inter);
}

.third-slider .swiper-slide-active .title{
	opacity: 1;
	transform: translateX(0px);
	-webkit-transition-delay: 500ms;
	-moz-transition-delay: 500ms;
	-ms-transition-delay: 500ms;
	-o-transition-delay: 500ms;
	transition-delay: 500ms;
}

.third-slider h1{
	font-size: var(--font-60);
	line-height: var(--font-70);
	opacity: 0;
	color:var(--white-color);
	transform-origin: top;
	transform: translateX(200px);
	-webkit-transition: all 1000ms ease;
	-moz-transition: all 100ms ease;
	-ms-transition: all 1000ms ease;
	-o-transition: all 1000ms ease;
	transition: all 1000ms ease;
}

.third-slider h1 span{
	font-weight:700;
	color:var(--color-two);
	text-decoration: underline;
	text-underline-offset: 6px;
}

.third-slider .swiper-slide-active h1{
	opacity: 1;
	transform: translateX(0px);
	-webkit-transition-delay: 800ms;
	-moz-transition-delay: 800ms;
	-ms-transition-delay: 800ms;
	-o-transition-delay: 800ms;
	transition-delay: 800ms;
}

.third-slider .text{
	opacity: 0;
	color:var(--white-color);
	transform-origin: top;
	font-size:var(--font-18);
	transform: translateX(200px);
	-webkit-transition: all 1000ms ease;
	-moz-transition: all 100ms ease;
	-ms-transition: all 1000ms ease;
	-o-transition: all 1000ms ease;
	transition: all 1000ms ease;
	margin-top:var(--space-20);
	margin-bottom:var(--space-35);
}

.third-slider .swiper-slide-active .text{
	opacity: 1;
	transform: translateX(0px);
	-webkit-transition-delay: 1200ms;
	-moz-transition-delay: 1200ms;
	-ms-transition-delay: 1200ms;
	-o-transition-delay: 1200ms;
	transition-delay: 1200ms;
}

.third-slider .options-box{
	margin-top: var(--space-30);
	opacity:0;
	transform: translateX(200px);
	transform-origin: top;
	display:inline-block;
	-webkit-transition: all 900ms ease;
	-moz-transition: all 900ms ease;
	-ms-transition: all 900ms ease;
	-o-transition: all 900ms ease;
	transition: all 900ms ease;
}

.third-slider .swiper-slide-active .options-box{
	opacity: 1;
	transform: translateX(0px);
	-webkit-transition-delay: 1600ms;
	-moz-transition-delay: 1600ms;
	-ms-transition-delay: 1600ms;
	-o-transition-delay: 1600ms;
	transition-delay: 1600ms;
}

.third-slider .button-box {
	gap: var(--space-25);
}
.third-slider .button-box .btn{
	display: block;
}

.third-slider .image-column{
	position:relative;
	z-index:2;
}

.third-slider .image-column .inner-column{
	position:relative;
	padding-right:var(--space-30);
}

.third-slider .image{
	position:relative;
	z-index:2;
	opacity: 0;
	transform: translateX(-200px);
	transform-origin: top;
	
	margin-left:-85px;
	-webkit-transition: all 1000ms ease;
	-moz-transition: all 100ms ease;
	-ms-transition: all 1000ms ease;
	-o-transition: all 1000ms ease;
	transition: all 1000ms ease;
}

.third-slider .swiper-slide-active .image{
	opacity: 1;
	transform: translateX(0px);
	-webkit-transition-delay: 600ms;
	-moz-transition-delay: 600ms;
	-ms-transition-delay: 600ms;
	-o-transition-delay: 600ms;
	transition-delay: 600ms;
}


/*** 

====================================================================
	Fourth Slider Section
====================================================================

***/

.third-slider{
	position:relative;
	overflow:hidden;
}

.fourth-slider .owl-dots,
.fourth-slider .owl-nav{
	display:none;
}

.fourth-slider .slide{
	position: relative;
	padding: 50px 0px 0px;
	background-size: cover;
	overflow: hidden;
}
.fourth-slider .image-layer img{
	position:absolute;
}

.fourth-slider .image-layer{
	position:absolute;
	left:0px;
	top:0px;
	right:0px;
	bottom:0px;
	background-size:cover;
	-webkit-transform:scale(1);
	-ms-transform:scale(1);
	transform:scale(1);
	background-position: center;
	-webkit-transition: all 6000ms linear;
	-moz-transition: all 6000ms linear;
	-ms-transition: all 6000ms linear;
	-o-transition: all 6000ms linear;
	transition: all 6000ms linear;
}

.fourth-slider .swiper-slide-active .image-layer{
	-webkit-transform:scale(1.15);
	-ms-transform:scale(1.15);
	transform:scale(1.15);
}

.fourth-slider .content-column{
	position:relative;
	z-index:2;
    padding-top: 200px;
	padding-bottom: 175px;
}
.fourth-slider .content-column .content-image-layer {
	position: absolute;
	left: 150%;
	bottom: -10px;
	width: 100%;
	transition: all 1.8s ease-in-out;
}
.fourth-slider .content-column .content-image-layer img {
	width: auto;
}
.fourth-slider .swiper-slide-active .content-column .content-image-layer {
	left: 78%;
}
.fourth-slider .content-column .inner-column{
	position:relative;
	z-index: 1;
}
.fourth-slider .title{
	position:relative;
	opacity: 0;
	z-index:1;
	font-family: var(--font-family-inter);
	font-size:var(--font-16);
	font-weight:500;
	text-transform:uppercase;
	letter-spacing: 2px;
	color: var(--color-four);
	transform-origin: top;
	display:inline-block;
	transform: translateX(200px);
	-webkit-transition: all 1000ms ease;
	-moz-transition: all 100ms ease;
	-ms-transition: all 1000ms ease;
	-o-transition: all 1000ms ease;
	transition: all 1000ms ease;
	margin-bottom:var(--space-15);
	font-family:var(--font-family-inter);
}

.fourth-slider .swiper-slide-active .title{
	opacity: 1;
	transform: translateX(0px);
	-webkit-transition-delay: 500ms;
	-moz-transition-delay: 500ms;
	-ms-transition-delay: 500ms;
	-o-transition-delay: 500ms;
	transition-delay: 500ms;
}

.fourth-slider h1{
	font-size: var(--font-66);
	line-height: var(--font-76);
	opacity: 0;
	color: var(--color-four);
	transform-origin: top;
	transform: translateX(200px);
	-webkit-transition: all 1000ms ease;
	-moz-transition: all 100ms ease;
	-ms-transition: all 1000ms ease;
	-o-transition: all 1000ms ease;
	transition: all 1000ms ease;
}

.fourth-slider h1 span{
	position: relative;
	font-weight:700;
	color: var(--main-color);
	background-image: url('../../../public/images/slider/underline.svg');
	background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
}
/* .fourth-slider h1 span::after {
	content: url('../../../public/images/slider/underline.png');
	position: absolute;
	left: 0;
	right: 0;
	bottom: -10px;
} */
.fourth-slider .swiper-slide-active h1{
	opacity: 1;
	transform: translateX(0px);
	-webkit-transition-delay: 800ms;
	-moz-transition-delay: 800ms;
	-ms-transition-delay: 800ms;
	-o-transition-delay: 800ms;
	transition-delay: 800ms;
}

.fourth-slider .text{
	opacity: 0;
	color: var(--color-three);
	transform-origin: top;
	font-size:var(--font-18);
	transform: translateX(200px);
	-webkit-transition: all 1000ms ease;
	-moz-transition: all 100ms ease;
	-ms-transition: all 1000ms ease;
	-o-transition: all 1000ms ease;
	transition: all 1000ms ease;
	margin-top:var(--space-20);
	margin-bottom:var(--space-35);
}

.fourth-slider .swiper-slide-active .text{
	opacity: 1;
	transform: translateX(0px);
	-webkit-transition-delay: 1200ms;
	-moz-transition-delay: 1200ms;
	-ms-transition-delay: 1200ms;
	-o-transition-delay: 1200ms;
	transition-delay: 1200ms;
}

.fourth-slider .options-box{
	opacity:0;
	transform: translateX(200px);
	transform-origin: top;
	display:inline-block;
	-webkit-transition: all 900ms ease;
	-moz-transition: all 900ms ease;
	-ms-transition: all 900ms ease;
	-o-transition: all 900ms ease;
	transition: all 900ms ease;
}

.fourth-slider .swiper-slide-active .options-box{
	opacity: 1;
	transform: translateX(0px);
	-webkit-transition-delay: 1600ms;
	-moz-transition-delay: 1600ms;
	-ms-transition-delay: 1600ms;
	-o-transition-delay: 1600ms;
	transition-delay: 1600ms;
}

.fourth-slider .button-box {
	gap: var(--space-25);
}
.fourth-slider .button-box .btn{
	display: block;
}

.fourth-slider .image-column{
	position:relative;
	z-index:2;
}

.fourth-slider .image-column .inner-column{
	position:relative;
	padding-right:var(--space-30);
}

.fourth-slider .image{
	position:relative;
	z-index:2;
	opacity: 0;
	transform: translateX(-200px);
	transform-origin: top;
	
	margin-left:-85px;
	-webkit-transition: all 1000ms ease;
	-moz-transition: all 100ms ease;
	-ms-transition: all 1000ms ease;
	-o-transition: all 1000ms ease;
	transition: all 1000ms ease;
}

.fourth-slider .swiper-slide-active .image{
	opacity: 1;
	transform: translateX(0px);
	-webkit-transition-delay: 600ms;
	-moz-transition-delay: 600ms;
	-ms-transition-delay: 600ms;
	-o-transition-delay: 600ms;
	transition-delay: 600ms;
}


/*** 

====================================================================
	Owl Carousel Nav/Dots Customization
====================================================================

***/

.owl-nav{
	position:absolute;
	left: 20px;
	top:50%;
	right: 20px;
	margin-top:-36px;
}

.owl-nav .owl-prev,
.owl-nav .owl-next{
	position:absolute;
	left:0px;
	width: 50px;
	height: 50px;
	line-height: 50px;
	text-align:center;
	border-radius: 5px;
	font-size:var(--font-20);
	color:var(--main-color);
	background-color:var(--white-color);
	transition:all 0.5s ease-in-out;
	-webkit-transition:all 0.5s ease-in-out;
	-moz-transition:all 0.5s ease-in-out;
	transition:all 0.5s ease-in-out;
}

.owl-nav .owl-next{
	left: auto;
	right:0px;
}

.owl-nav .owl-prev:hover,
.owl-nav .owl-next:hover{
	color:var(--color-two);
	background-color:var(--white-color);
	box-shadow: 0px 10px 60px rgba(137, 151, 186, 0.15);
}

/* Pagination */
.owl-carousel .owl-dots {
	text-align: center;
	margin-top: var(--space-25);
}
.owl-carousel .owl-dot {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: var(--main-color);
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 100%;
	transition: all 0.5s ease-in-out;
}
.owl-carousel .owl-dot:not(:last-child) {
	margin-right: 12px;
}
.owl-carousel .owl-dot.active {
	transform: scale(1.2);
	background-color: var(--color-two);
}


/*** 

====================================================================
	Service Section
====================================================================

***/

.services-section{
	position:relative;
	background-position: bottom center;
	background-repeat:repeat-x;
}

.services-section .sec-title .text{
	margin-top:var(--space-0);
}

.sec-title h1 span {
	color: var(--color-two);
}

.services-section .content-column{
	position:relative;
	margin-bottom:var(--space-30);
}

.services-section .content-column .inner-column{
	position:relative;
}

.service-block{
	position:relative;
	margin-bottom:var(--space-30);
}

.service-block .inner-box{
	position:relative;
}

.service-block .icon{
	position:relative;
	display:inline-block;
	border-radius: 50%;
	transition:all 0.5s ease-in-out;
	-webkit-transition:all 0.5s ease-in-out;
	-moz-transition:all 0.5s ease-in-out;
	transition:all 0.5s ease-in-out;
	margin-bottom:var(--space-25);
}

.service-block .inner-box:hover .icon{
	animation: icon-bounce 0.8s ease-out;
    -webkit-animation: icon-bounce 0.8s ease-out;
}

.service-block h5{
	font-size: var(--font-24);
	font-weight: 700;
}

.service-block h5 a{
	position:relative;
	color:var(--main-color);
}

.service-block h5 a:hover{
	color:var(--color-two);
}

.service-block .text{
	margin-top:var(--space-15);
}

.services-section .form-column{
	position:relative;
	margin-bottom:var(--space-30);
}

.services-section .form-column .inner-column{
	position:relative;
	border-radius: 15px;
	margin-left:var(--space-5);
	background-color:var(--white-color);
	box-shadow: 0px 10px 60px rgba(137, 151, 186, 0.15);
}

.services-section .form-column .title-box{
	position:relative;
	text-align:center;
	padding:55px 15px 70px;
	background-position:center top;
	background-repeat:repeat-x;
}

.services-section .form-column .title-box .title{
	position:relative;
	color:var(--white-color);
	font-size:var(--font-14);
	letter-spacing: 0.12em;
}

.services-section .form-column .title-box h4{
	position:relative;
	font-weight:600;
	color:var(--white-color);
	margin-top:var(--space-5);
}

.services-section .form-column .form-box{
	position:relative;
	padding:30px 50px 50px;
}

/* Service Block Style Two */
.service-block-style-two{
	position:relative;
	padding: 8px;
	transition:all 0.5s ease-in-out;
	-webkit-transition:all 0.5s ease-in-out;
	-moz-transition:all 0.5s ease-in-out;
	transition:all 0.5s ease-in-out;
}
.service-block-style-two:nth-child(2):before,
.service-block-style-two:nth-child(3):before {
	content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    bottom: -8px;
    left: -8px;
    background-color: var(--color-two);
}
.services-section.home .service-block-style-two:nth-child(2):before, 
.services-section.home .service-block-style-two:nth-child(3):before {
	display: none;
}

.service-block-style-two .inner-box{
	position:relative;
	z-index: 2;
	padding: 50px 35px;
	min-height: 375px;
	border: 1px solid var(--color-five);
	overflow: hidden;
	background-repeat: no-repeat;
	background-size: cover;
	transition:all 0.5s ease-in-out;
	-webkit-transition:all 0.5s ease-in-out;
	-moz-transition:all 0.5s ease-in-out;
	transition:all 0.5s ease-in-out;
}
.service-block-style-two .inner-box.bg-white{
	border-color: transparent;
	box-shadow: 0px 10px 60px rgba(137, 151, 186, 0.15);
}
.service-block-style-two .inner-box .hidden-image {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	visibility: hidden;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	transition: all 0.5s ease-in-out;
	-webkit-transition:all 0.5s ease-in-out;
	-moz-transition:all 0.5s ease-in-out;
	transition:all 0.5s ease-in-out;
}
.service-block-style-two:hover .inner-box .hidden-image {
	opacity: 1;
	visibility: visible;
}

.service-block-style-two .inner-box:after {
	content: url('../../../public/images/services/shape-1.png');
	position: absolute;
	top: -40px;
	right: 0;
	opacity: 0;
	visibility: hidden;
	transition:all 0.5s ease-in-out;
	-webkit-transition:all 0.5s ease-in-out;
	-moz-transition:all 0.5s ease-in-out;
	transition:all 0.5s ease-in-out;
}
.service-block-style-two:hover .inner-box:after {
	opacity: 1;
	visibility: visible;
	top: 0px;
}

.service-block-style-two .icon{
	position:relative;
	display:inline-block;
	transition:all 0.5s ease-in-out;
	-webkit-transition:all 0.5s ease-in-out;
	-moz-transition:all 0.5s ease-in-out;
	transition:all 0.5s ease-in-out;
	margin-bottom:var(--space-25);
}

.service-block-style-two:hover .inner-box .icon{
	animation: rotateme3 0.8s ease-out;
    -webkit-animation: rotateme3 0.8s ease-out;
}

.service-block-style-two .icon img {
	-webkit-transition:all 0.5s ease-in-out;
	-moz-transition:all 0.5s ease-in-out;
	transition:all 0.5s ease-in-out;
}

.service-block-style-two .icon .hidden_image {
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
	visibility: hidden;
}
.service-block-style-two:hover .inner-box .icon .open_image {
	opacity: 0;
	visibility: hidden;
}
.service-block-style-two:hover .inner-box .icon .hidden_image {
	opacity: 1;
	visibility: visible;
}

.service-block-style-two h5 a{
	position:relative;
	color:var(--main-color);
	transition:all 0.5s ease-in-out;
	-webkit-transition:all 0.5s ease-in-out;
	-moz-transition:all 0.5s ease-in-out;
	transition:all 0.5s ease-in-out;
}

.service-block-style-two:hover h5 a {
	color: var(--white-color);
}

.service-block-style-two .text{
	font-size: var(--font-17);
	margin-top:var(--space-15);
	transition:all 0.5s ease-in-out;
	-webkit-transition:all 0.5s ease-in-out;
	-moz-transition:all 0.5s ease-in-out;
	transition:all 0.5s ease-in-out;
}

.service-block-style-two:hover .text {
	color: var(--white-color);
}

.service-block-style-two .read-more{
	position:relative;
	font-weight:600;
	font-size:var(--font-17);
	color:var(--main-color);
	display: inline-flex;
	align-items: center;
	margin-top:var(--space-15);
	-webkit-transition:all 0.5s ease-in-out;
	-moz-transition:all 0.5s ease-in-out;
	transition:all 0.5s ease-in-out;
}

.service-block-style-two .read-more span{
	position: relative;
    width: 40px;
    height: 40px;
    background: var(--white-color);
	border-radius: 100%;
    text-align: center;
    line-height: 40px;
	margin-right: var(--space-10);
	box-shadow: 0px 10px 20px rgba(var(--color-two-rgb), 0.25);
	-webkit-transition:all 0.5s ease-in-out;
	-moz-transition:all 0.5s ease-in-out;
	transition:all 0.5s ease-in-out;
}

.service-block-style-two .read-more span:before{
	position: absolute;
    content: '';
	top: 0;
    left: 0;
	right: 0;
	bottom: 0;
    border: 1px dashed transparent;
	border-radius: 100%;
    text-align: center;
    line-height: 40px;
	transition:all 0.5s ease-in-out;
	-webkit-transition:all 0.5s ease-in-out;
	-moz-transition:all 0.5s ease-in-out;
	transition:all 0.5s ease-in-out;
}

.service-block-style-two:hover .read-more {
	color: var(--color-two);
}
.service-block-style-two .read-more:hover span:before {
	border-color: var(--color-two);
	animation: rotateme 5s linear infinite ;
}



/* Client Form */

.default-form{
	position:relative;
}

.default-form .form-group{
	position:relative;
	margin-bottom:var(--space-15);
}

.default-form .form-group .icon{
	position:absolute;
	right:20px;
	bottom:18px;
	line-height:1em;
	color:var(--color-six);
	font-size:var(--font-24);
}

.default-form input[type="text"],
.default-form input[type="email"],
.default-form input[type="password"],
.default-form select,
.default-form textarea{
	display:block;
	width:100%;
	line-height:28px;
	height:58px;
	padding:8px 22px;
	border-radius:5px;
	font-size:var(--font-16);
	color:var(--color-three);
	transition:all 0.5s ease-in-out;
	-webkit-transition:all 0.5s ease-in-out;
	-moz-transition:all 0.5s ease-in-out;
	transition:all 0.5s ease-in-out;
	background-color:var(--color-five);
	border:1px solid var(--color-five);
	font-family: var(--font-family-body);
}

.default-form .form-group textarea::-webkit-input-placeholder,
.default-form .form-group input::-webkit-input-placeholder{
	color:var(--color-three);
}

.default-form textarea.form-control{
	height:170px;
	resize:none;
	padding-top: 20px;
}

.default-form input[type="text"]:focus,
.default-form input[type="email"]:focus,
.default-form input[type="password"]:focus,
.default-form select:focus,
.default-form textarea:focus{
	border-color:var(--main-color);
}

.default-form .btn{
	cursor:pointer;
	width:100%;
	text-align:center;
}


.ui-datepicker td a{
	text-align:center;
}

/*** 

====================================================================
	About Section
====================================================================

***/

.about-section{
	position:relative;
	padding:var(--space-100) var(--space-0);
}

.about-section .image-column{
	position:relative;
}

.about-section .image-column .inner-column{
	position:relative;
}

.about-section .image-column .image{
	position:relative;
	overflow:hidden;
	border-radius:15px 0px 0px 15px;
}

.about-section .image-column .image img{
	position:relative;
	width: auto;
	max-width: fit-content;
	display:block;
}

.about-section .content-column{
	position:relative;
	z-index: 1;
}

.about-section .content-column .inner-column{
	position:relative;
}

.about-section .content-column .title-box{
	position:relative;
	margin-bottom:var(--space-40);
}

.about-section .content-column .title-box .title{
	position:relative;
	font-family:var(--font-family-heading);
	font-weight: 500;
	display:inline-block;
	color:var(--color-two);
	font-size:var(--font-16);
	text-transform: uppercase;
}
.about-section .content-column .title-box h1 {
	margin-bottom: 20px;
}
.about-section .content-column .title-box  p {
    position: relative;
    font-size: var(--font-16);
    line-height: 26px;
    color: var(--color-three);
}

.about-section .inner-container {
	position: relative;
	background: var(--white-color);
}

.about-section .work-list {
	background: #fff7f7;
    padding: 30px 30px 30px 30px;
    border-left: 6px solid #B00F0F;
    gap: 30px;
}
.about-section .work-list ul {
	padding: 0px;
}
.about-section .work-list ul li {
	position: relative;
	font-family: var(--font-family-heading);
    font-size: var(--font-16);
	font-weight: 700;
    line-height: 32px;
    color: #1C2539;
}
.about-section .work-list ul li img {
	margin-right: 9px;
}

.about-section .experience-counter {
	position: absolute;
    bottom: 0;
    right: 130px;
	transform-origin: bottom;
    transform: translateY(0%) perspective(600px) rotate3d(1, 0, 0, -90deg);
	transition: all 0.5s ease-in-out;
    /* animation: rotate1 1.5s linear infinite alternate-reverse; */
}
.about-section:hover .experience-counter {
    transform: translateY(0%) perspective(600px) rotate3d(1, 0, 0, 0deg);
}
.about-section .experience-counter .experience-counter-inner {
    background-color: var(--color-two);
    color: var(--white-color);
    width: 116px;
    height: 116px;
    text-align: center;
    font-family: var(--font-family-heading);
    font-size: var(--font-50);
    font-weight: 700;
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 15px 0px;
}
.about-section .experience-counter p {
    font-size: var(--font-16);
    line-height: 16px;
    color: var(--white-color);
    margin: 0px;
}
.about-section .experience-counter::after {
	content: url('../../../public/images/services/shape-5.png');
    position: absolute;
    right: 0%;
    bottom: -9px;
	z-index: -1;
	transition: all 0.5s ease-in-out;
}
.about-section:hover .experience-counter::after {
    right: 96%;
}

.author-block {
	margin-top: var(--space-30);
}
.author-block .author-profile {
	gap: 20px;
}
.author-block .author-profile h4 {
	font-size: var(--font-20);
}
.author-block .author-profile p {
	font-size: var(--font-17);
	color: var(--color-two);
	margin-bottom: 0px;
}

/*** 

====================================================================
	Business Section
====================================================================

***/

.business-section{
	position:relative;
	padding-top:var(--space-90);
}

.business-section .image-layer{
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	height: 680px;
	background-size: cover;
	z-index: -1;
}

.business-section .upper-box{
	position:relative;
	margin-bottom:var(--space-60);
}

.business-section .separator{
	position:relative;
	width:4px;
	height:70px;
	margin: 20px auto 0px;
	border-radius: 33.7963px;
	background-color:var(--white-color);
	margin-bottom:var(--space-25);
}

.business-section .separator:before{
	position:absolute;
	content:'';
	top:-12px;
	width:4px;
	height:8px;
	border-radius: 33.7963px;
	background-color:var(--white-color);
}

.business-section .separator:after{
	position:absolute;
	content:'';
	top:-19px;
	width:4px;
	height:4px;
	border-radius: 33.7963px;
	background-color:var(--white-color);
}

.business-section .upper-box h1{
	color:var(--white-color);
	text-align:center;
	font-weight:600;
	padding:0px 70px;
}

.business-section .upper-box h1 span{
	color:var(--color-two);
	font-weight:700;
}


.business-section .inner-container .video-post {
	border-radius:15px;
	overflow: hidden;
	margin-top: var(--space-0);
	margin-bottom: var(--space-0);
}
.business-section .inner-container .video-post .ytplay-btn {
	background-color: var(--white-color);
	color: var(--color-two);
}

.business-section .vector-icon{
	position:absolute;
	right:40px;
	top:-70px;
}

.business-section .image-column{
	position:relative;
}

.business-section .image-column .inner-column{
	position:relative;
}

.business-section .image-column .image{
	position:relative;
	overflow:hidden;
	border-radius:15px 0px 0px 15px;
}

.business-section .image-column .image img{
	position:relative;
	width: auto;
	max-width: fit-content;
	display:block;
}

.business-section .content-column{
	position:relative;
	z-index: 1;
}

.business-section .content-column .inner-column{
	position:relative;
	padding: var(--space-70) var(--space-60);
}

.business-section .content-column .title-box{
	position:relative;
	margin-bottom:var(--space-40);
}

.business-section .content-column .title-box .title{
	position:relative;
	font-family:var(--font-family-heading);
	font-weight: 700;
	display:inline-block;
	color:var(--color-two);
	font-size:var(--font-16);
	text-transform: uppercase;
}

.business-section .content-column .title-box h3{
	font-weight:600;
	color:var(--main-color);
	margin-top:var(--space-20);
}

/* Business section Home 1 */
.business-section.home {
	background-position: bottom;
	background-repeat: no-repeat;
	background-size: contain;
	padding-top: var(--space-120);
}
.business-section.home .inner-container {
	position: relative;
	background: var(--white-color);
	border-top: 3px solid var(--color-two);
	border-radius: 15px;
}
.business-section.home .inner-container::before {
	content: url('../../../public/images/background/layer-1.svg');
	position: absolute;
	left: -50px;
	top: -35px;
}

.business-section.home .inner-container::after {
	content: url('../../../public/images/background/layer-2.svg');
	position: absolute;
	right: 0;
	bottom: 0;
}
.business-section.home .image-layer {
	height: 500px;
}
.business-section.home .image-layer-bottom {
	position: absolute;
	left: 0px;
	bottom: 0px;
	right: 0px;
	height: 700px;
	background-size: cover;
	background-position: bottom center;
	z-index: -1;
}

/*** 

====================================================================
	Business Section Three
====================================================================

***/

.business-section-three{
	position:relative;
	padding-top:var(--space-90);
	overflow: hidden;
}

.business-section-three .image-layer{
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	height: 680px;
	background-size: cover;
	z-index: -1;
}

.business-section-three .upper-box{
	position:relative;
	margin-bottom:var(--space-60);
}

.business-section-three .upper-box h1{
	color:var(--white-color);
	text-align:center;
	font-weight:600;
	padding:0px 70px;
}

.business-section-three .upper-box h1 span{
	color:var(--color-two);
	font-weight:700;
}


.business-section-three .inner-container .video-post {
	border-radius:15px;
	overflow: hidden;
	margin-top: var(--space-0);
}
.business-section-three .inner-container .video-post .ytplay-btn {
	background-color: var(--white-color);
	color: var(--color-two);
}

.business-section-three .vector-icon{
	position:absolute;
	right:40px;
	top:-70px;
}

.business-section-three .image-column{
	position:relative;
}

.business-section-three .image-column .inner-column{
	position:relative;
}

.business-section-three .image-column .image{
	position:relative;
	overflow:hidden;
	border-radius:15px 0px 0px 15px;
}

.business-section-three .image-column .image img{
	position:relative;
	width: auto;
	max-width: fit-content;
	display:block;
}

.business-section-three .content-column{
	position:relative;
	z-index: 1;
}

.business-section-three .content-column .inner-column{
	position:relative;
	padding: var(--space-70) var(--space-60);
}

.business-section-three .content-column .title-box{
	position:relative;
	margin-bottom:var(--space-40);
}

.business-section-three .content-column .title-box .title{
	position:relative;
	font-family:var(--font-family-heading);
	font-weight: 700;
	display:inline-block;
	color:var(--color-two);
	font-size:var(--font-16);
	text-transform: uppercase;
}

.business-section-three .content-column .title-box h3{
	font-weight:600;
	color:var(--main-color);
	margin-top:var(--space-20);
}

.business-section-three .inner-container {
	position: relative;
	background: var(--white-color);
	border-top: 3px solid var(--color-two);
	border-radius: 15px;
	margin-top: var(--space-100);
}
.business-section-three .inner-container::before {
	content: url('../../../public/images/background/layer-1.svg');
	position: absolute;
	left: -50px;
	top: -35px;
	z-index: -1;
	overflow: hidden;
}

.business-section-three .inner-container::after {
	content: url('../../../public/images/background/layer-2.svg');
	position: absolute;
	right: 0;
	bottom: 0;
}

.business-section-three .image-layer {
	height: 100%;
	z-index: -2;
}
.business-section-three .image-layer-bottom {
	position: absolute;
	left: 0px;
	bottom: 0px;
	right: 0px;
	height: 700px;
	background-size: cover;
	background-position: bottom center;
	z-index: -1;
}

.business-section-three .service-block-style-two .inner-box {
	border-width: 0px;
}
.business-section-three .service-block-style-two .inner-box:before {
    content: url(../../../public/images/services/shape-2.png);
    position: absolute;
    top: -1px;
    right: 0;
    opacity: 1;
    visibility: visible;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}
.business-section-three .service-block-style-two:hover .inner-box:before {
	opacity: 0;
	visibility: hidden;
}
.business-section-three .service-block-style-two:hover h5 a {
	color: var(--color-two);
}

.service-block-style-two.all-services-link .inner-box {
	display: grid;
    align-items: center;
    grid-gap: 90px;
}

.service-block-style-two.all-services-link .inner-box .btn {	
	width: auto;
	max-width: 200px;
	padding: 19px 30px;
	color: var(--color-two);
}
.service-block-style-two.all-services-link .inner-box .btn .btn-wrap .text-one, 
.service-block-style-two.all-services-link .inner-box .btn .btn-wrap .text-two {
	color: var(--color-two);
}

.service-block-style-two.all-services-link .inner-box::before,
.service-block-style-two.all-services-link .inner-box::after {
	display: none;
}

.business-section-three .service-block-style-two.all-services-link  h5 {
	font-size: var(--font-34);
	line-height: 40px;
	font-weight: 700;
	color: var(--white-color);
}
.business-section-three .service-block-style-two:nth-child(2):before, 
.business-section-three .service-block-style-two:nth-child(3):before {
	display: none;
}

/* Features Block  */
.feature-block{
	position:relative;
	margin-bottom:var(--space-40);
}

.feature-block .inner-box{
	position:relative;
	line-height:28px;
	color:var(--color-three);
	font-size:var(--font-18);
	padding-left:var(--space-85);
}

.feature-block .icon{
	position:absolute;
	left:0px;
	top:0px;
	width:53px;
	height:53px;
	line-height: 55px;
	border-radius: 50%;
	text-align:center;
	background-color:var(--white-color);
	color:var(--color-two);
	font-size:var(--font-28);
	transition: all 600ms ease;
    -moz-transition: all 600ms ease;
    -webkit-transition: all 600ms ease;
    -ms-transition: all 600ms ease;
    -o-transition: all 600ms ease;
	box-shadow: 0px 10px 20px rgba(137, 151, 186, 0.25);
}

.feature-block .inner-box:hover .icon{
	-webkit-transform: scale(-1) rotate(180deg);
    -moz-transform: scale(-1) rotate(180deg);
    -ms-transform: scale(-1) rotate(180deg);
    -o-transform: scale(-1) rotate(180deg);
    transform: scale(-1) rotate(180deg);
}

.feature-block h5{
	font-weight:700;
	color:var(--main-color);
	margin-bottom:var(--space-10);
	font-family:var(--font-family-heading);
}


.feature-block.two .icon{
	position:absolute;
	left:0px;
	top:0px;
	width: 60px;
	height: 60px;
	line-height: 58px;
	border-radius: 50%;
	text-align:center;
	background-color: #fce7e7;
	color: var(--color-two);
	font-size:var(--font-28);
	padding: 0px 10px;
	transition: all 600ms ease;
    -moz-transition: all 600ms ease;
    -webkit-transition: all 600ms ease;
    -ms-transition: all 600ms ease;
    -o-transition: all 600ms ease;
	box-shadow: none;
}

.business-section-three .btn {
	width: 150px;
	min-width: auto;
}

/* Counter Box */

.counter-box{
	position:relative;
	padding: 90px 0px 60px;
}

.counter-column{
	position:relative;
	text-align:center;
	margin-bottom:var(--space-30);
}

.counter-box .counter{
	position:relative;
	font-weight: 700;
	font-size:var(--font-60);
	color:var(--color-two);
	font-family:var(--font-family-heading);
}

.counter-box .counter i{
	position:relative;
	font-style:normal;
	top:3px;
	left:5px;
}

.counter-box h6{
	font-weight:600;
	font-size:var(--font-16);
	color:var(--white-color);
	margin-top:var(--space-10);
	padding-bottom:var(--space-20);
	font-family:var(--font-family-body);
}

.counter-box h6:before{
	position:absolute;
	content:'';
	left:50%;
	bottom:0px;
	width:46px;
	height:4px;
	border-radius:50px;
	margin-left:-23px;
	background-color:var(--color-two);
}

/*** 

====================================================================
	Process Section
====================================================================

***/

.process-section{
	position:relative;
	padding:110px 0px 70px;
}

.process-section .inner-container{
	position:relative;
}

.process-section .separater-line{
	position:absolute;
	left:60px;
	top:60px;
	right:60px;
	height:89px;
	background-position:center center;
}

.process-block{
	position:relative;
	margin-bottom:var(--space-30);
}

.process-block .inner-box{
	position:relative;
	text-align:center;
}

.process-block .image-outer{
	position:relative;
	display:inline-block;
}

.process-block .image-outer .image{
	position:relative;
	width:195px;
	height:195px;
	overflow:hidden;
	border-radius:50%;
}

.process-block .inner-box .number{
	position:absolute;
	left:0px;
	top:0px;
	z-index:1;
	width:52px;
	height:52px;
	font-weight:600;
	line-height:52px;
	outline: 5px solid rgba(255, 255, 255, 0.5);
	border-radius:50px;
	display:inline-block;
	font-size:var(--font-20);
	color:var(--white-color);
	transition: all 600ms ease;
    -moz-transition: all 600ms ease;
    -webkit-transition: all 600ms ease;
    -ms-transition: all 600ms ease;
    -o-transition: all 600ms ease;
	background-color:var(--color-two);
	font-family:var(--font-family-heading);
}

.process-block .inner-box:hover .number{
	animation: icon-bounce 0.8s ease-out;
    -webkit-animation: icon-bounce 0.8s ease-out;
}

.process-block .lower-content{
	position:relative;
	margin-top:var(--space-25);
}

.process-block h4{
	position:relative;
	color:var(--main-color);
}

.process-block .text{
	color:var(--color-three);
	margin-top:var(--space-15);
}

/*** 

====================================================================
	Business Section Two
====================================================================

***/

.business-section-two{
	position:relative;
	padding: 120px 0px 40px;
	overflow: hidden;
}

.business-section-two .content-column{
	position:relative;
}

.business-section-two .content-column .inner-column{
	position:relative;
}

.business-section-two .title-box{
	position:relative;
	margin-bottom:var(--space-40);
}

.business-section-two .title-box .title{
	position: relative;
    font-family: var(--font-family-heading);
    font-weight: 700;
    display: inline-block;
    color: var(--color-two);
    font-size: var(--font-16);
    text-transform: uppercase;
}

.business-section-two .title-box h1{
	margin-top:var(--space-20);
}

.business-section-two .title-box .text{
	position:relative;
	font-size:var(--font-16);
	margin-top: var(--space-15);
	margin-bottom:var(--space-25);
	padding-bottom:var(--space-25);
	border-bottom:1px solid rgba(var(--black-color-rgb), 0.07);
}

.business-section-two .button-box{
	position:relative;
	margin-top: var(--space-15);
}

.business-section-two .options{
	position:relative;
}

.business-section-two .options li{
	position:relative;
	display: flex;
	font-family:var(--font-family-heading);
	font-size:var(--font-16);
	font-weight: 700;
	align-items: center;
	background-color: var(--white-color);
	color:var(--main-color);
	border: 1px solid #E1E9F4;
	border-radius: 5px;
	margin-bottom:var(--space-15);
}

.business-section-two .options li .icon{
	flex-shrink: 0;
    width: 55px;
    height: 55px;
    line-height: 55px;
    display: inline-block;
    background: #ffeeef;
    text-align: center;
    border-radius: 5px 0px 0px 5px;
	margin: -1px 20px -1px -1px;
}
.business-section-two .circle-image {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 482px;
    height: 483px;
    margin-top: -241px;
    margin-left: -200px;
    background-repeat: no-repeat;
}

.play-box{
	position:relative;
	font-size:var(--font-16);
	font-weight: 700;
	color:var(--main-color);
	margin-left:var(--space-25);
}
.play-box:hover {
	color: var(--color-two);
}

.play-box span{
	position:relative;
	width: 80px;
	height: 80px;
	line-height: 52px;
	border-radius:50px;
	text-align:center;
	display:inline-block;
	font-size:var(--font-16);
	color:var(--color-two);
	background-color: #ffeeef;
	border: 13px solid var(--white-color);
	box-shadow: 0px 0px 0px 1px var(--color-two);
	margin-right: var(--space-20);
	transition:all 0.5s ease-in-out;
	-webkit-transition:all 0.5s ease-in-out;
	-moz-transition:all 0.5s ease-in-out;
	transition:all 0.5s ease-in-out;
}

.play-box:hover span{
	background-color: var(--color-two);
	color: var(--white-color);
}

.play-box span:before,
.play-box span:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80px;
	height: 80px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-border-radius: 50% 50% 50% 50%;
    border-radius: 50% 50% 50% 50%;
    -webkit-box-shadow: 0px 0px 0px 20px rgba(223, 10, 10, 0.4);
    box-shadow: 0px 0px 0px 20px rgba(223, 10, 10, 0.4);
    -webkit-animation: ripple1 2s ease-in-out infinite;
    animation: ripple1 2s ease-in-out infinite;
}

.play-box span:after {
    -webkit-box-shadow: 0px 0px 0px 40px rgba(223, 10, 10, 0.3);
    box-shadow: 0px 0px 0px 40px rgba(223, 10, 10, 0.3);
    -webkit-animation: ripple2 2s ease-in-out infinite;
    animation: ripple2 2s ease-in-out infinite;
}

@keyframes ripple1 {
    0% {box-shadow: 0px 0px 0px 0px rgba(223, 10, 10, 0.4);}
    50% {box-shadow: 0px 0px 0px 20px rgba(223, 10, 10, 0);}
    100% {box-shadow: 0px 0px 0px 0px rgba(223, 10, 10, 0);}
}
@keyframes ripple2 {
    0% {box-shadow: 0px 0px 0px 20px rgba(223, 10, 10, 0.3);}
    50% {box-shadow: 0px 0px 0px 40px rgba(223, 10, 10, 0);}
    100% {box-shadow: 0px 0px 0px 0px rgba(223, 10, 10, 0);}
}

.business-section-two .image-column{
	position:relative;
}

.business-section-two .image-column .inner-column{
	position:relative;
}

.business-section-two .image-column .image{
	position:relative;
	text-align:right;
}

.business-section-two .graph-image{
	position:absolute;
	left:-180px;
	bottom:0px;
	width:544px;
	height:347px;
	background-repeat:no-repeat;
	z-index: -1;
}

.business-section-two::before{
	content: url(../../../public/images/resource/curve-line.svg);
    position: absolute;
    top: -70px;
    left: 0;
    z-index: -2;
	overflow: hidden;
}

.business-section-two .experiance-layer{
	position:absolute;
	left:0px;
	top:60px;
	z-index:1;
	width:193px;
	height:193px;
	background-repeat:no-repeat;
}

.business-section-two .experiance-layer:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	z-index:-1;
	width:193px;
	height:193px;
	/* background:url(../../../public/images/resource/experience-bg.png); */
	background-repeat:no-repeat;
}

.business-section-two .shadow-layer{
	position:absolute;
	left:0px;
	bottom:0px;
	width:1015px;
	height:611px;
	background-repeat:no-repeat;
}


/*** 

====================================================================
	Call To Action Section
====================================================================

***/

.call-to-action {
	background-size: cover;
	background-position: center;
	padding: var(--space-80) var(--space-0);
}
.call-to-action h1 {
	font-weight: 700;
}
.call-to-action .btn-three {
	font-family: var(--font-family-heading);
	font-size: var(--font-16);
	font-weight: 700;
	padding: 17px 24px;
}

/*** 

====================================================================
	Testimonial Section
====================================================================

***/

.testimonial-section{
	position:relative;
	padding: 100px 0px 100px;
	background-position: center;
	background-size: cover;
	overflow: hidden;
}

.testimonial-section:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	right:0px;
	bottom:0px;
	opacity:0.90;
}
.testimonial-section > * {
	position: relative;
	z-index: 1;
}

.testimonial-section .title-column{
	position:relative;
}

.testimonial-section .title-column .inner-column{
	position:relative;
	padding-top:var(--space-60);
}


.testimonial-section .carousel-column{
	position:relative;
}

.testimonial-section .carousel-column .inner-column{
	position:relative;
}


.testimonial-section .carousel-column .owl-nav{
	display:none;
}

.testimonial-section .carousel-column .owl-dots{
	position:relative;
	margin-top:var(--space-10);
	margin-left:var(--space-15);
	text-align: center;
}


.testimonial-section .carousel-column .owl-dots .owl-dot{
	position:relative;
	width:50px;
	height:4px;
	display:inline-block;
	margin-right:var(--space-10);
	background-color:var(--color-nine);
	border-radius: 0;
}

.testimonial-section .carousel-column .owl-dots .owl-dot:hover,
.testimonial-section .carousel-column .owl-dots .owl-dot.active{
	background-color:var(--color-two);
	transform: scale(1);
}

.testimonial-section .owl-carousel .owl-stage-outer{
	position:static;
	padding:20px 0px;
}

.testimonial-section .owl-theme .testimonial-block{
	margin:0px 15px;
}

.testimonial-section .owl-carousel{
	margin:0px -15px;
	width:auto;
}

.testimonial-section.two .carousel-column .owl-dots {
	display: inline-block;
	left: 322px;
}

.testimonial-block{
	position:relative;
	margin-bottom:var(--space-30);
}

.testimonial-block .inner-box{
	position:relative;
	overflow:hidden;
	border-radius: 10px;
	background-color:var(--white-color);
	border-bottom: 3px solid var(--color-two);
	box-shadow: 0px 5px 15px rgba(137, 151, 186, 0.15);
}

.testimonial-block .upper-box{
	position:relative;
	padding:35px 35px;
}

.testimonial-block .rating{
	position:relative;
	font-weight: 700;
	font-size:var(--font-18);
	color:var(--main-color);
	font-family:var(--font-family-heading);
}

.testimonial-block .rating .fa{
	position:relative;
	color:var(--color-eight);
}

.testimonial-block .text{
	position:relative;
	font-size:var(--font-16);
	margin-top:var(--space-15);
}

.testimonial-block .lower-box{
	position:relative;
	padding: 30px 35px 30px;
	border-top: 1px solid var(--color-five);
}

.testimonial-block .lower-box .author-box{
	position:relative;
	min-height:79px;
}

.testimonial-block .lower-box .box-inner{
	position:relative;
	padding-top:var(--space-15);
	padding-left:var(--space-100);
}

.testimonial-block .lower-box .author-box .author-image{
	position:absolute;
	left:0px;
	top:0px;
	width:79px;
	height:79px;
}

.testimonial-block .lower-box .author-box .author-image img{
	position:relative;
	border-radius:50%;
	overflow:hidden;
}

.testimonial-block .lower-box .author-box strong{
	position:relative;
	font-family:var(--font-family-heading);
	font-size:var(--font-20);
	color:var(--main-color);
}

.testimonial-block .lower-box .author-box .designation{
	position:relative;
	display:block;
	font-size:var(--font-16);
	color:var(--color-six);
	margin-top:var(--space-5);
}

.testimonial-block .lower-box .quote{
	position:absolute;
	right:0px;
	top:0px;
	width:29px;
	height:29px;
	z-index:1;
	line-height:30px;
	text-align:center;
	border-radius:50px;
	display:inline-block;
	font-size:var(--font-14);
	color:var(--white-color);
	background-color:var(--color-two);
}

/*** 

====================================================================
	Testimonial Section Two
====================================================================

***/
.testimonial-section.two .title-column .inner-column {
	padding-top: var(--space-100);
}
.testimonial-section.two .carousel-column {
    position: relative;
	width: 100%;
	margin-right: -100%;
}

/*** 

====================================================================
	News Section
====================================================================

***/

.news-section{
	position:relative;
	padding:20px 0px 100px;
}

.news-section .owl-dots{
	display:none;
}

.news-section .owl-nav{
	position:absolute;
	left:-100px;
	top:50%;
	right:-100px;
	margin-top:-36px;
}

.news-section .owl-nav .owl-prev{
	position:absolute;
	left:0px;
	width:72px;
	height:72px;
	line-height:70px;
	text-align:center;
	border-radius:50px;
	font-size:var(--font-30);
	color:var(--main-color);
	background-color:var(--color-ten);
	transition:all 0.5s ease-in-out;
	-webkit-transition:all 0.5s ease-in-out;
	-moz-transition:all 0.5s ease-in-out;
	transition:all 0.5s ease-in-out;
}

.news-section .owl-nav .owl-next{
	position:absolute;
	right:0px;
	width:72px;
	height:72px;
	line-height:70px;
	text-align:center;
	border-radius:50px;
	font-size:var(--font-30);
	color:var(--main-color);
	background-color:var(--color-ten);
	transition:all 0.5s ease-in-out;
	-webkit-transition:all 0.5s ease-in-out;
	-moz-transition:all 0.5s ease-in-out;
	transition:all 0.5s ease-in-out;
}

.news-section .owl-nav .owl-prev:hover,
.news-section .owl-nav .owl-next:hover{
	color:var(--color-two);
	background-color:var(--white-color);
	box-shadow: 0px 10px 60px rgba(137, 151, 186, 0.15);
}

.news-section .owl-carousel .owl-stage-outer{
	position:static;
	padding:20px 0px;
}

.news-section .owl-theme .news-block{
	margin:0px 15px;
}

.news-section .owl-carousel{
	margin:0px -15px;
	width:auto;
}

.news-block{
	position:relative;
	margin-bottom:var(--space-30);
}

.news-block .inner-box{
	position:relative;
	border-radius: 10px;
	overflow:hidden;
	background-color:var(--white-color);
	box-shadow: 0px 5px 15px rgba(137, 151, 186, 0.15);
}

.news-block .image{
	position:relative;
	overflow:hidden;
	background-color:var(--color-two);
}

.news-block .image img{
	position:relative;
	width:100%;
	display:block;
	transition:all 0.5s ease-in-out;
	-webkit-transition:all 0.5s ease-in-out;
	-moz-transition:all 0.5s ease-in-out;
	transition:all 0.5s ease-in-out;
	overflow: hidden;
}

.news-block .inner-box:hover .image img{
	opacity:0.70;
	transform:scale(1.04,1.04) rotate(1deg);
}

.news-block .lower-content{
	position:relative;
}

.news-block .post-date{
	position:absolute;
	right:0px;
	top:-15px;
	font-weight:600;
	text-transform: uppercase;
	display:inline-block;
	font-size:var(--font-14);
	line-height: 22px;
	color:var(--white-color);
	padding: 4px 10px 2px 15px;
	border-radius:4px 0px 0px 4px;
	background-color:var(--color-two);
}

.news-block .author-box{
	position:relative;
	padding:15px 35px;
	border-bottom:1px solid rgba(var(--color-two-rgb), 0.10);
}

.news-block .author-box .content{
	position:relative;
	padding-top:var(--space-5);
	padding-left:var(--space-60);
}

.news-block .author-box .author-image{
	position:absolute;
	left:0px;
	top:0px;
	overflow:hidden;
	border-radius:50%;
	border:3px solid var(--color-two);
}

.news-block .author-box strong{
	position:relative;
	font-weight: 700;
	display:block;
	font-size:var(--font-16);
	color:var(--main-color);
	font-family:var(--font-family-heading);
}

.news-block .author-box .designation{
	position:relative;
	font-weight:400;
	font-size:var(--font-12);
	color:var(--color-three);
}

.news-block .lower-box{
	position:relative;
	padding:25px 35px 35px;
}


.news-block h5 a{
	position:relative;
	color:var(--main-color);
}

.news-block h5 a:hover{
	color:var(--color-two);
}

.news-block .lower-box .text{
	font-size:var(--font-16);
	margin-top:var(--space-10);
}

.news-block .read-more{
	position:relative;
	font-weight: 600;
	font-size:var(--font-17);
	color:var(--main-color);
	display:inline-block;
	margin-top:var(--space-20);
	padding-right:var(--space-20);
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
}

.news-block .read-more:before{
	position:absolute;
	content:"\f105";
	right: 0px;
	font-family: 'FontAwesome';
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
}

.news-block .read-more:hover{
	color:var(--color-two);
}
.news-block .read-more:hover:before{
	right: 5px;
}

/*** 

====================================================================
	Accordion Section
====================================================================

***/

.accordion-section {
	background-position: center;
    background-size: cover;
	padding-top: var(--space-120);
}
.accordion {
	border-radius: 15px 15px 0px 0px;
	background-color: var(--white-color);
	padding: var(--space-30) var(--space-30);
	margin-top: var(--space-20);
	box-shadow: 0px 10px 60px rgba(137, 151, 186, 0.15);
}

.accordion-item {
	border-width: 0px;
}

.accordion-button {
	font-family: var(--font-family-heading);
	font-size: var(--font-16);
	font-weight: 700;
	line-height: 24px;
	color: var(--main-color);
	padding: var(--space-20) var(--space-30);
	display: flex;
    align-items: center;
    justify-content: space-between;
}

.accordion-button:not(.collapsed) {
	background-color: #ffeeef;
	color: var(--color-two);
	box-shadow: none;
}

.accordion-button::after,
.accordion-button:not(.collapsed)::after {
	display: none;
}

.accordion-button:focus {
	box-shadow: none;
	border-color: transparent;
}

.accordion-tab-icon {
	position: relative;
	font-size: var(--font-24);
	color: var(--main-color);
}
.accordion-tab-icon .open-icon {	
	-webkit-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
}
.accordion-tab-icon .close-icon {
	position: absolute;
	left: 0;
	color: var(--color-two);
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
}

.accordion-button:not(.collapsed) .accordion-tab-icon .open-icon {
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
}
.accordion-button:not(.collapsed) .accordion-tab-icon .close-icon {
	opacity: 1;
	visibility: visible;
}

.accordion-body {
	font-size: var(--font-18);
	line-height: 28px;
	color: var(--color-six);
	background-color: #ffeeef;
	padding: var(--space-0) var(--space-30) var(--space-25);
}

.more-query {
	background-color: var(--main-color);
	border-radius: 0px 0px 15px 15px;
	text-align: center;
	color: var(--white-color);
	padding: var(--space-30);
	margin-bottom: var(--space-100);
	box-shadow: 0px 10px 60px rgba(137, 151, 186, 0.10);
}

.more-query p {
	color: var(--white-color);
	margin: 0px;
}

.more-query a {
	color: var(--color-two);
}

/* User Group */
.user-group {
	margin-top: 30px;
	flex-flow: row wrap;
}
.avatar-group {
	display: flex;
	align-items: center;
}
  
.avatar-group .avatar:hover {
	z-index: 2;
}

.avatar-group .avatar + .avatar {
	margin-left: -15px;
}

.avatar-group .avatar {
	width: 65px;
	height: 65px;
	border: 5px solid var(--white-color);
}

.avatar-group .avatar .avatar-initials {
	font-size: var(--font-20);
}
.avatar-group .text {
	margin-left: 20px;
}
.avatar-group .text h4 {
	line-height: 24px;
	color: var(--color-two);
}
.avatar-group .text p {
	font-weight: 600;
	line-height: 24px;
	color: var(--main-color);
	margin-bottom: 0px;
}

.user-group .rating-point {
	padding: 0px 30px;
	background: rgb(255,255,255);
	background: -moz-linear-gradient(90deg, rgba(255,255,255,0.4) 0%, rgba(223,10,10,0.05) 50%, rgba(255,255,255,0.4) 100%);
	background: -webkit-linear-gradient(90deg, rgba(255,255,255,0.4) 0%, rgba(223,10,10,0.05) 50%, rgba(255,255,255,0.4) 100%);
	background: linear-gradient(90deg, rgba(255,255,255,0.4) 0%, rgba(223,10,10,0.05) 50%, rgba(255,255,255,0.4) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
}
.user-group .rating-point .vote {
	font-family: var(--font-family-heading);
	font-size: 60px;
	font-weight: 800;
	line-height: 72px;
	color: var(--white-color);
	margin-bottom: 0px;
	background-image: url('../../../public/images/services/bg-2.png');
	background-repeat: repeat;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.user-group .rating-point .divider {
	font-size: 42px;
	font-weight: 800;
}

.user-group .rating-point .total {
	font-family: var(--font-family-heading);
	font-size: 36px;
	font-weight: 700;
	line-height: 40px;
}

/* Mobile Responsive Menu Button */


element {

}
[type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled), button:not(:disabled) {

    cursor: pointer;

}
.mobileMenu-toggle {

    cursor: pointer;
    color: #1c2539;
    background-color: #ffeeef;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    width: 50px;
    height: 50px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: none;
    font-size: 24px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transition: all 0.5s ease-in-out;
}