.investment .main-header .header-top .left-box {
    order: 2;
}
.investment .main-header .header-top .social-box li{
	margin-right:var(--space-25);
}

.investment .main-header .header-top .social-box li:last-child{
	margin-right:var(--space-0);
}
.investment .main-header .outer-box {
    display: flex;
    align-items: center;
    gap: var(--space-20);
}
.investment .main-header .outer-box .btn {
    padding: 17px 30px;
}
.investment .main-header .aside_open {
    min-width: auto;
    width: 56px;
    height: 56px;
    line-height: 56px;
    padding: 0;
    border-radius: 15px;
    text-align: center;
    font-size: 32px;
    background: #e6eefb;
    color: var(--color-seven);
}
.investment .main-header .aside_open span {
    font-size: var(--font-28);
}

.investment .btn {
    border-radius: 15px;
}
.investment .btn-two {
    box-shadow: 0px 10px 60px rgba(137, 151, 186, 0.25);
}


/* Section Title */
.investment .sec-title .title {
    font-size: var(--font-13);
    font-weight: 400;
    line-height: var(--font-20);
    letter-spacing: 3.9px;
}
.investment .sec-title h1 {
    font-size: var(--font-40);
    font-weight: 700;
    line-height: var(--font-48);
    margin-bottom: var(--space-25);
}
.investment .sec-title h1 span {
    color: var(--color-two);
}
.investment .sec-title .separator {
    width: 76px;
    height: 2px;
    border-radius: 15px;
    background-color: #DBE6FF;
    display: inline-block;
    margin-top: 0px;
    margin-bottom: 4px;
    margin-right: 5px;
}
.investment .sec-title .separator::before {
    display: none;
}
.investment .sec-title .separator::after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    width: 19px;
    height: 2px;
    border-radius: 15px;
    background-color: var(--color-two);
}

.investment .sec-title .button-box {
    margin-top: var(--space-30);
}

/* Service Section */
.investment .services-section .inner-column.left {
    padding-right: var(--space-50);
}
.investment .service-block h5 a {
    color: #18304B;
}
.investment .service-block-style-two .inner-box:after {
    content: url(../../../public/images/services/shape-6.png);
}

/* Read More Button */
.investment .service-block .read-more {
    margin-top: var(--space-20);
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease-in-out;
}
.investment .service-block:hover .read-more {
    opacity: 1;
    visibility: visible;
}
.investment .service-block a.read-more {
    display: flex;
    column-gap: var(--space-10);
    font-size: var(--font-14);
    font-weight: 700;
    text-transform: uppercase;
    color: var(--color-two);
    transition: all 0.5s ease-in-out;
}
.investment .service-block a.read-more:hover {
    color: #518CF5;
}
.investment .service-block a.read-more span {
    position: relative;
    overflow: hidden;
    width: 20px;
}
.investment .service-block a.read-more span i {
    position: relative;
    left: 0;
    transition: all 0.5s ease-in-out;
}
.investment .service-block a.read-more span .hidden {
    position: absolute;
    left: -110%;
    top: 50%;
    transform: translateY(-50%);
}
.investment .service-block a.read-more:hover span .visible {
    left: 110%;
}
.investment .service-block a.read-more:hover span .hidden {
    left: 0;
}


/* Case Study Section */
.investment .case-study-section {
    padding: 100px 0px 70px;
    background-position: top center;
    background-repeat: no-repeat;
}

/* Case Block Style */
.investment .case-block{
    position:relative;
    border-radius: 15px;
	transition:all 0.5s ease-in-out;
	-webkit-transition:all 0.5s ease-in-out;
	-moz-transition:all 0.5s ease-in-out;
	transition:all 0.5s ease-in-out;
}
.investment .case-block:hover {
    box-shadow: 0px 10px 15px rgba(70, 77, 94, 0.15);
}
.investment .case-block .inner-box{
	position:relative;
	z-index: 2;
    border-radius: 15px;
	overflow: hidden;
	transition:all 0.5s ease-in-out;
	-webkit-transition:all 0.5s ease-in-out;
	-moz-transition:all 0.5s ease-in-out;
	transition:all 0.5s ease-in-out;
}
.investment .case-block .inner-box .block-image img {
    width: 100%;
}
.investment .case-block .block-bottom {
    position: absolute;
    left: 22px;
    bottom: 25px;
    width: calc(100% - 44px);
    overflow: hidden;
}
.investment .case-block .block-bottom .front {
    position: relative;
    padding: var(--space-20) var(--space-25);
    border-radius: 9px;
    background: var(--white-color);
    transition: all 0.5s ease-in-out;
    overflow: hidden;
}
.investment .case-block .block-bottom .front::before {
    content: '';
    position: absolute;
    right: -30px;
    bottom: -12px;
    background-color: var(--color-two);
    width: 100px;
    height: 35px;
    transform: rotate(-40deg);
}
.investment .case-block .block-bottom .front::after {
    content: '';
    position: absolute;
    right: -30px;
    bottom: -13px;
    background-color: var(--color-seven);
    width: 100px;
    height: 20px;
    transform: rotate(-40deg);
}

.investment .case-block .block-bottom .back {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    transform: rotateX(-180deg);
    padding: var(--space-20) var(--space-25);
    border-radius: 9px;
    background: var(--color-two);
    background: -moz-linear-gradient(90deg, var(--color-two) 0%, var(--color-seven) 100%);
    background: -webkit-linear-gradient(90deg, var(--color-two) 0%, var(--color-seven) 100%);
    background: linear-gradient(90deg, var(--color-two) 0%, var(--color-seven) 100%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease-in-out;
}
.investment .case-block:hover .block-bottom .front {
    opacity: 0;
    visibility: hidden;
    transform: rotateX(-180deg);
}
.investment .case-block:hover .block-bottom .back {
    opacity: 1;
    visibility: visible;
    transform: rotateX(0deg);
}
.investment .case-block h5{
    font-size: var(--font-18);
	font-weight: 700;
    line-height: var(--font-26);
}

.investment .case-block h5 a{
	position:relative;
	color:var(--main-color);
	transition:all 0.5s ease-in-out;
	-webkit-transition:all 0.5s ease-in-out;
	-moz-transition:all 0.5s ease-in-out;
	transition:all 0.5s ease-in-out;
}
.investment .case-block .block-bottom .back a {
	color: var(--white-color);
}
.investment .case-block .text{
	font-size: var(--font-14);
    color: var(--color-two);
	transition:all 0.5s ease-in-out;
	-webkit-transition:all 0.5s ease-in-out;
	-moz-transition:all 0.5s ease-in-out;
	transition:all 0.5s ease-in-out;
}
.investment .case-block .block-bottom .back .text {
	color: var(--white-color);
}
/* Read More Button */
.investment .case-block a.read-more {
    position: absolute;
    right: 12px;
    bottom: 12px;
    font-size: var(--font-34);
    font-weight: 700;
    text-transform: uppercase;
    color: var(--white-color);
    transition: all 0.5s ease-in-out;
}
.investment .case-block a.read-more span {
    position: relative;
    width: 34px;
    height: 34px;
    display: inline-block;
    overflow: hidden;
}
.investment .case-block a.read-more span i {
    position: relative;
    left: 0;
    transition: all 0.5s ease-in-out;
}
.investment .case-block a.read-more span .hidden {
    position: absolute;
    left: -110%;
    top: 50%;
    transform: translateY(-50%);
}
.investment .case-block a.read-more:hover span .visible {
    left: 110%;
}
.investment .case-block a.read-more:hover span .hidden {
    left: 0;
}

/* Owl Case */
.investment .case-list .owl-theme .case-block {
    margin: var(--space-30) var(--space-15);
}

.investment .case-carousel .owl-nav {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    top: auto;
    right: auto;
    margin-top: 20px;
    text-align: center;
    display: flex;
    column-gap: 12px;
    justify-content: center;
}
.investment .case-carousel .owl-nav::before,
.investment .case-carousel .owl-nav::after {
    content: '';
    position: absolute;
    top: 50%;
    width: calc(50% - 90px);
    height: 1px;
    background-color: #E0E8F4;
}
.investment .case-carousel .owl-nav::before{
    left: 15px;
}
.investment .case-carousel .owl-nav::after {
    left: auto;
    right: 15px;
}
.investment .case-carousel .owl-nav .owl-prev, 
.investment .case-carousel .owl-nav .owl-next {
    position: relative;
    left: auto;
    width: 38px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    border-radius: 100%;
    font-size: var(--font-16);
    box-shadow: 0px 0px 30px rgba(137, 151, 186, 0.25);
}
.investment .case-carousel .owl-nav .owl-next {
    left: auto;
    right: auto;
}


/* Why Choose Section */
.why-choose-section .content-column .inner-column{
    padding-bottom: 130px;
}
.why-choose-section .image-column {
    position: relative;
    align-self: end;
    overflow: hidden;
}
.why-choose-section .image-column::before {
    content: url('../../../public/images/resource/circle-layer-3.png');
    position: absolute;
    left: 70px;
    top: 20px;
}
.why-choose-section .image-column .image {
    position: relative;
    transform-style: preserve-3d;
}
.why-choose-section .image-column .image::before {
    content: url('../../../public/images/resource/team.png');
    position: absolute;
    left: -15px;
    top: -40px;
}
.why-choose-section .image-column .image::after {
    content: url('../../../public/images/resource/chart.png');
    position: absolute;
    right: -60px;
    bottom: 70px;     
    animation-duration: 7s;
}
.why-choose-section .feature-block .icon {
    width: 60px;
    height: 60px;
    line-height: 60px;
}

.why-choose-section .button-box {
    gap: 15px;
}
.why-choose-section .play-box span {
    position: relative;
    width: 80px;
    height: 80px;
    line-height: 60px;
    border-radius: 50px;
    text-align: center;
    display: inline-block;
    font-size: var(--font-30);
    color: var(--color-two);
    border: 13px solid var(--white-color);
    box-shadow: 0px 0px 15px 5px #f5f5f5;
    margin-right: var(--space-20);
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    background: var(--color-two);
    background: -moz-linear-gradient(90deg, var(--color-two) 0%, var(--color-seven) 100%);
    background: -webkit-linear-gradient(90deg, var(--color-two) 0%, var(--color-seven) 100%);
    background: linear-gradient(90deg, var(--color-two) 0%, var(--color-seven) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.why-choose-section .play-box span:before,
.why-choose-section .play-box span:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80px;
	height: 80px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-border-radius: 50% 50% 50% 50%;
    border-radius: 50% 50% 50% 50%;
    -webkit-box-shadow: 0px 0px 0px 20px rgba(56, 203, 247, 0.4);
    box-shadow: 0px 0px 0px 20px rgba(56, 203, 247, 0.4);
    -webkit-animation: ripple9 2s ease-in-out infinite;
    animation: ripple9 2s ease-in-out infinite;
}

.why-choose-section .play-box span:after {
    -webkit-box-shadow: 0px 0px 0px 40px rgba(56, 203, 247, 0.3);
    box-shadow: 0px 0px 0px 40px rgba(56, 203, 247, 0.3);
    -webkit-animation: ripple10 2s ease-in-out infinite;
    animation: ripple10 2s ease-in-out infinite;
}

@keyframes ripple9 {
    0% {box-shadow: 0px 0px 0px 0px rgba(56, 203, 247, 0.4);}
    50% {box-shadow: 0px 0px 0px 20px rgba(56, 203, 247, 0.0);}
    100% {box-shadow: 0px 0px 0px 0px rgba(56, 203, 247, 0);}
}
@keyframes ripple10 {
    0% {box-shadow: 0px 0px 0px 20px rgba(56, 203, 247, 0.3);}
    50% {box-shadow: 0px 0px 0px 40px rgba(56, 203, 247, 0);}
    100% {box-shadow: 0px 0px 0px 0px rgba(56, 203, 247, 0);}
}

.call-to-action .call-to-action-inner {
    position: relative;
    width: calc(100% - 140px);
    left: 70px;
    text-align: center;
    color: #fff;
    padding-top: var(--space-90);
    padding-bottom: var(--space-100);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 30px;
    overflow: hidden;
}
.call-to-action .call-to-action-inner .image-layer {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all 8s ease-in-out;
}
.call-to-action .call-to-action-inner:hover .image-layer {
    transform: scale(1.2);
    transform-origin: center center;
}
.call-to-action p{
    font-size: var(--font-24);
    line-height: var(--font-30);
    letter-spacing: 1px;
    color: #bdd6f1;
    margin-top: var(--space-15);
    margin-bottom: var(--space-35);
}

.business-section-four {
    overflow: hidden;
    padding-top: 360px;
    margin-top: -230px;
}
.business-section-four::before {
    content: url('../../../public/images/resource/shape-tropo.png');
    position: absolute;
    right: 49%;
    top: 10px;
    bottom: 0;
    z-index: -1;
}
.business-section-four .content-column {
    position: relative;
}
.business-section-four .content-column::after {
    content: url('../../../public/images/resource/circle.png');
    position: absolute;
    left: 90%;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
}


/* Funfacts */
.investment .counter-box.style-two {
    padding: var(--space-0);
    display: -moz-inline-grid;
    display: -ms-inline-grid;
    display: inline-grid;
    flex-shrink: 0;
}
.investment .counter-box.style-two .counter-column{
    position: relative;
    background-color: var(--white-color);
    display: inline-block;
    border-radius: 10px;
    overflow: hidden;
    padding: var(--space-35) var(--space-25);
    box-shadow: 0px 0px 15px rgba(245, 249, 255, 0.6);
}
.investment .counter-box.style-two .counter-column::before {
    content: url('../../../public/images/resource/shape-round.png');
    position: absolute;
    top: -1px;
    right: -4px;
}
.investment .counter-box.style-two .counter-column .counter-icon {
    position: relative;
    background: #f5f9ff;
    display: inline-block;
    width: 90px;
    height: 90px;
    text-align: center;
    line-height: 90px;
    border-radius: 100%;
    outline: 10px solid rgba(255, 255, 255, 0.1);
}
.investment .counter-box.style-two .counter {
    font-size: var(--font-36);
    font-weight: 700;
    line-height: var(--font-26);
    margin-top: var(--space-10);
}
.investment .counter-box.style-two h6 {
    font-size: var(--font-18);
    color: var(--main-color);
    font-weight: 700;
    margin-top: var(--space-5);
    padding-bottom: var(--space-0);
}
.investment .counter-box.style-two h6::before {
    display: none;
}

.investment .business-section-four .image-column .inner-column {
    column-gap: 30px;
}
.investment .business-section-four .feature-block .icon {
    background-color: #eef3f3;
    box-shadow: none;
    width: 60px;
    height: 60px;
    line-height: 58px;
}
.investment .business-section-four .btn {
    box-shadow: 0px 10px 60px rgba(137, 151, 186, 0.25);
}

/* Company Growth */
.company-growth .inner-container {
    position: relative;
    background-color: var(--white-color);
    padding: 60px 70px;
    overflow: hidden;
    border-radius: 15px;
}
.company-growth .inner-container::after {
    content: url('../../../public/images/resource/layer-1.png');
    position: absolute;
    right: 0;
    bottom: -10px;
}
.company-growth .inner-container > *{
    position: relative;
    z-index: 1;
}
.investment .company-growth .inner-container .sec-title {
    margin-bottom: var(--space-35);
}
.investment .company-growth .inner-container .sec-title h1{
    column-gap: 10px;
    margin-bottom: var(--space-0);
}
.investment .company-growth .inner-container .sec-title h1 span.achieve-count {
    font-size: 96px;
    color: #518CF5;
    align-items: center;
    flex-shrink: 0;
}
.investment .company-growth .inner-container .sec-title h1 span.achieve-count span.odometer {
    flex-shrink: 0;
}
.investment .company-growth .inner-container .sec-title h1 span {
    color: #518CF5;
}
/* Newsletter */
.subscribe-area {
    margin-top: var(--space-100);
    color: var(--white-color);
}
.subscribe-area h2 {
    font-size: var(--font-40);
    line-height: var(--font-48);
    color: var(--white-color);
}
.subscribe-area .subscribe-form .form-group{
    position: relative;
}
.subscribe-area .subscribe-form .form-group input[type=email] {
    height: 56px;
    border-radius: 15px ;
    padding: 18px 30px;
    color: #6F7F90;
    font-size: var(--font-16);
}
.subscribe-area .subscribe-form .form-group .btn {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    background-color: var(--main-color);
    border-radius: 0px 15px 15px 0px;
}
.subscribe-area .subscribe-form .form-group .btn::before {
    display: none;
}
.subscribe-area .subscribe-form .form-group .btn:hover {
    background-color: var(--color-two);
}

.investment .testimonial-section {
    background-color: #f5f9ff;
}


/* Testimonial */
.investment .testimonial-section {
    padding-bottom: var(--space-0);
}
.investment .testimonial-section .title-column .inner-column {
    padding-top: var(--space-35);
}
.investment .testimonial-section .owl-theme .testimonial-block {
    margin: var(--space-0);
}
.investment .testimonial-block .inner-box {
    position: relative;
    overflow: hidden;
    border-radius: 0px;
    background-color: transparent;
    border-bottom: 0px solid var(--color-two);
    box-shadow: none;
    padding: var(--space-15);
}

.investment .testimonial-block .inner-box .upper-box {
    position: relative;
    border-radius: 10px;
    background-color: var(--white-color);
    box-shadow: 0px 10px 20px rgba(137, 151, 186, 0.25);
}
.investment .testimonial-block .inner-box .upper-box::before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 15px solid var(--white-color);
    z-index: 9;
    bottom: -15px;
    left: 50px;
}

.investment .testimonial-block .inner-box .upper-box .quote {
    position: absolute;
    top: 25px;
    right: 30px;
    z-index: 1;
    line-height: 30px;
    text-align: center;
    border-radius: 50px;
    display: inline-block;
    font-size: var(--font-30);
    color: rgba(81, 140, 245, 0.2);
}
.investment .testimonial-block .rating {
    font-size: var(--font-13);
    color: var(--color-eight);
}
.investment .testimonial-block .lower-box .box-inner {
    padding-top: var(--space-10);
    padding-left: var(--space-80);
}
.investment .testimonial-block .lower-box .author-box .author-image {
    width: var(--space-60);
    height: var(--space-60);
}
.investment .testimonial-block .lower-box .author-box .designation {
    margin-top: var(--space-0);
}
.investment .testimonial-block .lower-box .author-box strong {
    font-size: var(--font-18);
    font-weight: 700;
}

.investment .testimonial-section .carousel-column .owl-dots {
    position: absolute;
    margin-top: 0;
    margin-left: 0;
    text-align: center;
    right: -40px;
    top: 100px;
    display: grid;
    row-gap: 12px;
}

.investment .testimonial-section .carousel-column .owl-dots .owl-dot {
    position: relative;
    width: 12px;
    height: 12px;
    display: inline-block;
    margin-right: 0;
    background-color: var(--color-nine);
    outline: 4px solid rgba(81, 141, 245, 0);
    border-radius: 100%;
    transition: all 0.5s ease-in-out;
}

.investment .testimonial-section .carousel-column .owl-dots .owl-dot:hover, 
.investment .testimonial-section .carousel-column .owl-dots .owl-dot.active {
    background-color: #518CF5;
    outline: 4px solid rgba(81, 141, 245, 0.5);
    transition: all 0.5s ease-in-out;
}

/* Clients */
.our-clients {
    border-top: 1px solid #eaeaea;
    padding: 70px 0px;
}
.our-clients .client-logo {
    text-align: center;
}

/* News */
.investment .news-block .author-box strong {
    color: #273D71;
}
.news-block .image {
    background-color: var(--color-seven);
}
.investment .news-block h5 a:hover {
    color: var(--color-seven);
}
.investment .news-block .post-date {
    background-color: var(--color-seven);
}
.investment .news-block .author-box .author-image {
    border-color: var(--color-seven);
}
.investment .news-block .read-more {
    font-size: var(--font-14);
    font-weight: 700;
    text-transform: uppercase;
    color: #273D71;
}
.investment .news-block .read-more:before {
    content: "\F64D";
    font-family: 'bootstrap-icons';
}
.investment .news-block .read-more:hover {
    color: var(--color-seven);
}

/* Contact Section  */
.investment .default-form .form-control {
    background-color: #effbfe;
}

/* FAQ */
.investment .accordion-button:not(.collapsed),
.investment .accordion-body {
  background-color: #effbfe;
}

/* Footer */
.investment .main-footer {
    background-color: #0e2049;
}
.investment .main-footer .widgets-section {
    padding-top: var(--space-90);
    padding-bottom: var(--space-60);
}

.investment .main-footer .large-text h1{
    font-family: 'DM Sans', sans-serif;
    font-size: 73px;
    line-height: 72px;
    font-weight: 700;
    background: linear-gradient(90deg, #38C9F7 0%, #266FF2 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
.investment .main-footer .large-text h2{
    font-family: 'DM Sans', sans-serif;
    font-size: 57px;
    line-height: 72px;
    font-weight: 700;
    background: linear-gradient(90deg, #38C9F7 0%, #266FF2 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-stroke: thin transparent;
    color: #0e2049;
}

.investment .logo-column .logo {
    margin-bottom: var(--space-30)
}
.investment .logo-column p {
    font-size: var(--font-16);
    line-height: var(--font-26);
    color: #bdd6f1;
}
.investment .main-footer .contact-list li {
    margin-bottom: var(--space-0);
    padding-bottom: var(--space-5);
    border: none;
}

.investment .main-footer .social-box {
    margin-top: var(--space-30);
}

.investment .main-footer .social-box li a {
    font-size: var(--font-17);
    background-color: #203258;
    color: var(--white-color);
    width: 38px;
    height: 38px;
    line-height: 38px;
    border-radius: 10px;
}
.investment .main-footer .social-box li a:hover {
    background-color: var(--color-seven);
}

.investment .main-footer .links li a {
    font-weight: 400;
    color: #bdd6f1;
}

.investment .main-footer .footer-bottom .copyright {
    font-size: var(--font-16);
}
.investment .main-footer .footer-bottom .copyright a {
    color: var(--color-seven);
}
.investment .terms-policy a {
    position: relative;
    color: #bdd6f1;
}
.investment .terms-policy a:not(:last-child) {
    padding-right: var(--space-25);
    margin-right: var(--space-25);
}
.investment .terms-policy a:hover {
    color: var(--color-seven);
}
.investment .terms-policy a:not(:last-child)::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 100%;
    background-color: rgba(217, 217, 217, 0.1);;
}

/* Sidebar  */
.investment .widget_tag_cloud a {
    background-color: #effbfe;
}
.investment .widget_tag_cloud a:hover {
    background-color: var(--color-two);
}

/* Page Header */
.investment .page_header {
    background-image: url(../../../public/images/background/37.jpg);
}
.investment .page_header::before {
    display: none;
}
.investment .page_header .breadcrumb li,
.investment .page_header .breadcrumb li a {
    color: var(--main-color);
}
.investment .page_header .breadcrumb li.active,
.investment .page_header .breadcrumb li a:hover {
    color: var(--color-two);
}